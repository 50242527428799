<template>
  <section class="margin__md">
    <div class="tile-heading padding__sm">
      <h2>Favorite Orders</h2>
      <hr />
    </div>
    <p>
      Search for a favorite by its name below.
      <br />By clicking on the favorite order you want to use, you are selecting the products which will be automatically added to the this order.
      <br />You may add or remove products as necessary.
      <br />Confirmation details like comments and thank you note will also be pre-filled for this order.
    </p>
    <div class="search--bar">
      <input type="text" v-model="search" placeholder="search" />
      <div class="btn__search">
        <font-awesome-icon icon="search" size="1x" />
      </div>
    </div>
    <div v-if="!getFavoriteOrders || filteredFavorites.length <= 0" class="padding__md flex__center">
      <p class="text__bold">No Favorite Orders found</p>
    </div>
    <simplebar v-else class="favorites--overflow" data-simplebar-auto-hide="false">
      <ul class="favorites__grid">
        <Favorite
          v-for="order in filteredFavorites"
          :key="order._id"
          :favorite="order"
          :active="favorite"
          @setActive="setFavorite"
        />
      </ul>
    </simplebar>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Favorite from "./Favorite";
export default {
  components: {
    Favorite
  },
  data() {
    return {
      search: "",
      favorite: null
    };
  },
  computed: {
    ...mapGetters(["getFavoriteOrders"]),
    filteredFavorites() {
      if (!this.getFavoriteOrders) return null;
      return this.getFavoriteOrders.filter(fav =>
        fav.favoriteName.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  },
  methods: {
    setFavorite(id) {
      this.favorite = id;
      this.$emit("setFavorite", id);
    }
  }
};
</script>

<style scoped lang="scss">
.favorites--overflow {
  max-height: 425px;
}
.favorites__grid {
  padding: 25px 0;
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 25px);
}
.search--bar {
  max-width: 500px;
  margin: 25px auto;
}
@media (min-width: $md) {
  .favorites__grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $xl) {
  .favorites__grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>