<template>
  <section class="container order-details">
    <h1 class="text__center padding__sm">New Order: Details</h1>
    <section class="form__grid margin__md">
      <div>
        <p>For every borrower / referral you are allowed to spend $25. All Items listed with Respa are at an approved amount. Taxes, shipping, and processing fees do not count toward that $25 so some products may cost more than $25 but still be Respa approved.</p>
        <p
          class="warning--message"
        >Please note that all orders are subject to taxes and fees. Individual products do not have taxes nor fees included in the price. There may be shipping delays due to complications with the seller of an individual product.</p>
      </div>
      <div class="order-details--form">
        <label :class="[errors.recipientType ? 'form--error' : null]">
          Recipient Type*
          <select v-model="form.recipientType">
            <option value></option>
            <option value="Borrower">Borrower</option>
            <option value="Referral Partner">Referral Partner</option>
            <option value="Internal Employee">Internal Employee</option>
          </select>
        </label>
        <form-error :message="errors.recipientType" />
        <!-- <label
          v-if="form.recipientType !== 'Internal Employee'"
          :class="[errors.loanNumber ? 'form--error' : null]"
        >
          Loan Number{{ form.recipientType === "Borrower" ? "*" : "" }}
          <input
            type="text"
            v-mask="'##########'"
            v-model="form.loanNumber"
            placeholder="1234567890"
          />
        </label>
        <form-error :message="errors.loanNumber" /> -->
        <label :class="[errors.recipientName ? 'form--error' : null]">
          {{ form.recipientType === "Borrower" ? "Borrower Name*" : "Recipient Name*" }}
          <input
            type="text"
            v-model="form.recipientName"
            placeholder="first and last"
            @change="setShippingName"
          />
        </label>
        <form-error :message="errors.recipientName" />
        <label>
          Co-Recipient Name
          <input
            type="text"
            v-model="form.coRecipientName"
            placeholder="first and last"
          />
        </label>
        <label :class="[errors.shippingName ? 'form--error' : null]">
          Shipping Name*
          <input type="text" v-model="form.shippingName" placeholder="first and last" />
        </label>
        <form-error :message="errors.shippingName" />
        <label>
          Shipping Address*
          <div class="address__block" :class="[errors.shippingAddress ? 'form--error' : null]">
            <input
              type="text"
              v-model="form.shippingAddress.street"
              class="address__street"
              placeholder="street"
              name="street"
            />
            <input
              type="text"
              v-model="form.shippingAddress.city"
              class="address__city"
              placeholder="city"
              name="city"
            />
            <select v-model="form.shippingAddress.state" class="address__state" name="state">
              <option value>State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
            <input
              type="text"
              v-model="form.shippingAddress.zip"
              v-mask="'#####'"
              class="address__zip"
              placeholder="zip"
              name="zip"
            />
          </div>
        </label>
        <form-error :message="errors.shippingAddress" />
        <label :class="[errors.shippingPhone ? 'form--error' : null]">
          <p>
            <span>Shipping Phone*</span>
            <br />
            <span class="text__sm">For delivery purposes</span>
          </p>
          <input
            type="tel"
            v-model="form.shippingPhone"
            v-mask="'(###) ###-####'"
            placeholder="(123) 456-7890"
          />
        </label>
        <form-error :message="errors.shippingPhone" />
        <label
          v-if="form.recipientType !== 'Internal Employee'"
          :class="[errors.milestone ? 'form--error' : null]"
        >
          Loan Milestone{{form.recipientType === 'borrower' ? '*' : null}}
          <select
            v-model="form.milestone"
          >
            <option value></option>
            <option value="Application">Application</option>
            <option value="Under Contract">Under Contract</option>
            <option value="Closing">Closing</option>
            <option v-if="form.recipientType === 'Referral Partner'" value="Referral">Referral</option>
          </select>
        </label>
        <form-error :message="errors.milestone" />
        <div class="order-details--buttons flex__right margin__sm">
          <button class="btn btn__green text__md" @click.prevent="next">
            Select Products
            <font-awesome-icon icon="arrow-right" size="1x" class="ml-10" />
          </button>
        </div>
      </div>
    </section>
    <Favorites v-if="!favoriteSet" @setFavorite="setFavorite" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Favorites from "./Favorites";
export default {
  components: {
    Favorites
  },
  metaInfo: {
    title: "New Order Details | WOW"
  },
  data() {
    return {
      isValidated: true,
      favoriteSet: false,
      form: {
        favorite: null,
        requester: "",
        requesterName: "",
        requesterEmail: "",
        requester2Email: "",
        recipientType: "",
        loanNumber: "",
        recipientName: "",
        coRecipientName: "",
        shippingName: "",
        shippingAddress: {
          street: "",
          city: "",
          state: "",
          zip: ""
        },
        shippingPhone: "",
        milestone: ""
      },
      errors: {
        recipientType: null,
        loanNumber: null,
        recipientName: null,
        shippingName: null,
        shippingAddress: null,
        shippingPhone: null,
        milestone: null
      }
    };
  },
  computed: {
    ...mapGetters(["getUserDetails"])
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startCreateOrder",
      "startSetActiveFavorite"
    ]),
    setShippingName(evt) {
      this.form.shippingName = evt.target.value;
    },
    async next() {
      this.validateForm();
      if (!this.isValidated) return null;
      this.startSetIsSubmitting({ bool: true, heading: "Creating" });
      const form = this.form;
      form.requester = this.getUserDetails._id;
      form.requesterName = this.getUserDetails.displayName;
      form.requesterEmail = this.getUserDetails.email;
      form.requester2Email = this.getUserDetails.email2;
      // form.billingUser = this.getUserDetails._id;
      // form.billingName = this.getUserDetails.displayName;
      // form.billingEmail = this.getUserDetails.email;
      try {
        const order = await this.startCreateOrder(form);
        this.startSetIsSubmitting({ bool: false });
        this.startSetActiveFavorite(null);
        this.$router.push(`/new-order/${order._id}/products`);
      } catch (err) {
        this.startSetActiveFavorite(null);
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validateForm() {
      this.isValidated = true;
      // this.errors.requesterId = !this.form.requesterId
      //   ? "You must be logged in to submit a new order"
      //   : null;
      this.errors.recipientType =
        this.form.recipientType === ""
          ? "Please select a recipient type"
          : null;
      // if (this.form.recipientType === "Borrower") {
      //   this.errors.loanNumber =
      //     this.form.loanNumber.length < 10
      //       ? "Please enter a full loan number"
      //       : null;
      // } else {
      //   this.errors.loanNumber = null;
      // }
      this.errors.recipientName =
        this.form.recipientName === ""
          ? "Please enter a name for the recipient"
          : null;
      this.errors.shippingName =
        this.form.shippingName === "" ? "Please enter a shipping name" : null;
      if (
        this.form.shippingAddress.street === "" ||
        this.form.shippingAddress.city === "" ||
        this.form.shippingAddress.state === "" ||
        this.form.shippingAddress.zip === "" ||
        this.form.shippingAddress.zip.length < 5
      ) {
        this.errors.shippingAddress =
          "Please enter a full value for all parts of the address";
      } else {
        this.errors.shippingAddress = null;
      }
      this.errors.shippingPhone =
        this.form.shippingPhone === "" ? "Please enter a phone number" : null;
      this.errors.shippingPhone =
        this.form.shippingPhone.length < 14
          ? "Please enter all digits of the phone number"
          : null;
      this.errors.milestone =
        this.form.recipientType === "Borrower" && this.form.milestone === ""
          ? "Please select a milestone"
          : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    setFavorite(id) {
      this.form.favorite = id;
    },
    setQuery() {
      const qs = this.$route.query;
      if (qs.id) {
        this.form.favorite = qs.id;
        this.favoriteSet = true;
      }
      if (qs.recipienttype) {
        this.form.recipientType = qs.recipienttype;
      }
      if (qs.loannumber) {
        this.form.loanNumber = qs.loannumber;
      }
      if (qs.recipientname) {
        this.form.recipientName = qs.recipientname;
        this.form.shippingName = qs.recipientname;
      }
      if (qs.coborrower) {
        this.form.coRecipientName = qs.coborrower;
      }
      if (qs.street) {
        this.form.shippingAddress.street = qs.street;
      }
      if (qs.city) {
        this.form.shippingAddress.city = qs.city;
      }
      if (qs.state) {
        this.form.shippingAddress.state = qs.state;
      }
      if (qs.zip) {
        this.form.shippingAddress.zip = qs.zip;
      }
      if (qs.phone) {
        this.form.shippingPhone = qs.phone;
      }
    }
  },
  mounted() {
    this.setQuery();
  }
};
</script>

<style scoped lang="scss">
.order-details--form {
  @include grid($row-gap: 15px);
}
.order-details--form label {
  @include grid($cols: 25% 70%, $align: start, $col-gap: 5%);
}
.warning--message {
  color: #fff;
  background-color: $orange;
  padding: 10px;
  border-radius: 5px;
}
</style>