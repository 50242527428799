<template>
  <li class="favorite-tile tile pos__rel pointer" @click="setActive" :class="isActive">
    <div class="favorite-tile--status flex__center">
      <span class="text__bold text__white">Favorite</span>
    </div>
    <div class="favorite-row">
      <span>Name:</span>
      <span class="text__overflow">{{ favorite.favoriteName }}</span>
    </div>
    <div class="favorite-row">
      <span>Total Cost:</span>
      <span class="text__pine text__bold">${{ favorite.totalCost.toFixed(2) }}</span>
    </div>
    <simplebar class="small--overflow" data-simplebar-auto-hide="false">
      <ul>
        <li v-for="orderItem in favorite.orderItems" :key="orderItem._id" class="flex__between">
          <span>{{ orderItem.productName }}</span>
          <span>${{ (orderItem.quantity * orderItem.loCost).toFixed(2) }}</span>
        </li>
      </ul>
    </simplebar>
    <div class="favorite-text">
      <span class="text__bold">Thank You:</span>
      <simplebar class="small--overflow" data-simplebar-auto-hide="false">
        <span class="pre-wrap">{{ favorite.thankYouNote }}</span>
      </simplebar>
    </div>
    <div class="favorite-text">
      <span class="text__bold">Comments:</span>
      <simplebar class="small--overflow" data-simplebar-auto-hide="false">
        <span class="pre-wrap">{{ favorite.comments }}</span>
      </simplebar>
    </div>
  </li>
</template>

<script>
export default {
  props: ["favorite", "active"],
  computed: {
    isActive() {
      return this.favorite._id === this.active ? "selected" : null;
    }
  },
  methods: {
    setActive() {
      this.$emit("setActive", this.favorite._id);
    }
  }
};
</script>

<style scoped lang="scss">
.favorite-tile {
  @include grid($cols: 1fr, $row-gap: 8px);
  background-color: lighten($frost, 5%);
  transition: all 0.25s ease-in-out;
}
.favorite-tile:hover {
  @include shadow();
  background-color: lighten($frost, 10%);
}
.selected {
  background-color: $pistachio;
  transition: background-color 0.25s ease-in-out;
}
.selected:hover {
  background-color: $powder;
}
.favorite-tile--status {
  @include pos-abs($pos: absolute, $top: -5px, $left: -5px, $width: 150px);
  background-color: $peacock;
  clip-path: polygon(0% 0%, 100% 0, 90% 100%, 0% 100%);
  padding-right: 10px;
}
.favorite-row {
  @include grid($cols: auto 1fr, $col-gap: 5px);
  span {
    font-weight: bold;
  }
  span:nth-child(2) {
    text-align: right;
    font-weight: normal;
  }
  .text__bold {
    font-weight: bold !important;
  }
}
ul {
  padding-left: 15px;
  @include grid($cols: 1fr, $row-gap: 5px);
}
.favorite-text {
  @include grid($cols: 1fr, $row-gap: 5px);
}
.small--overflow {
  max-height: 75px;
}
</style>